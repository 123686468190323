<template>
  <div class="main has-header has-footer">
    <mt-header fixed title="实名认证">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="tips">请确认识别出的信息与身份证一致，不一致需重新拍摄</div>
    <ul class="check-info">
      <li>
        <span class="label txt-gray-light">真实姓名：</span>
        <span class="info txt-black">{{user.userName}}</span>
      </li>
      <li>
        <span class="label txt-gray-light">身份证号：</span>
        <span class="info txt-black">{{user.idNum}}</span>
      </li>
    </ul>
    <hr class="space" />
    <div class="checkForm">
      <div class="preview clearfix">
        <upload-idcard imgType="imgFront" :initImg="user.userIdcardImg1" @upBace="updateStatus"></upload-idcard>
      </div>
      <div class="preview clearfix">
        <upload-idcard imgType="imgBack" :initImg="user.userIdcardImg2" @upBace="updateStatus"></upload-idcard>
      </div>
    </div>
    <div class="footer-bt bg-white">
      <mt-button type="danger" size="large" @click="handleNext">下一步</mt-button>
    </div>
  </div>
</template>

<script>
import { userStatus } from "@utils/uploadImg";
import uploadIdcard from "@components/user/upload-idcard";
import { MessageBox } from "mint-ui";
import { getUserInfo } from "@/api/user";
export default {
  components: {
    uploadIdcard,
  },
  data() {
    return {
      user: {},
      imgFront: {
        msg: "",
        status: true,
      },
      imgBack: {
        msg: "",
        status: true,
      },
    };
  },
  beforeDestroy() {
    const messageBox = document.getElementsByClassName(
      "mint-msgbox-btn mint-msgbox-cancel "
    );

    if (messageBox && messageBox[0]) {
      messageBox[0].click();
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getUserInfo();
  },
  methods: {
    updateStatus(obj) {
      this[obj.imgType].msg = obj.msg;
      this[obj.imgType].status = obj.status;
      if (obj.userState > 1) {
        this.user.state = obj.userState;
      }
      if (obj.userName) {
        this.user.userName = obj.userName;
      }
      if (obj.idNum) {
        this.user.idNum = obj.idNum;
      }
    },
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.user = res.data;
      });
    },
    handleNext() {
      if (this.user.state === 2) {
        this.$router.push("/user/authentication-success");
      } else {
        let msg =
          this.imgFront.msg || this.imgBack.msg || userStatus(this.user.state);
        MessageBox(msg);
      }
    },
  },
};
</script>

<style lang='less' scoped>
.contack-wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 50%;
  right: 0px;
  bottom: auto;
  left: auto;
  transform: translate3d(0px, -50%, 0px);
  transition: all 1s ease-out 1s;
}

.tips {
  color: #ff2500;
  background-color: #fff2ef;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
  text-align: left;
  padding-left: 5px;
  text-align: center;
}

ul.check-info {
  padding: 20px;
  li {
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 2rem;
    .label {
      margin-right: 5px;
    }
  }
}

.checkForm {
  padding: 24px 42px 150px;
  input.file {
    display: none;
  }
  .preview {
    position: relative;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
    .prev-tip {
      position: relative;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      height: 2.5rem;
      line-height: 2.5rem;
      margin-top: -2.5rem;
      color: #fff;
      text-align: center;
    }
  }
}
</style>