<template>
  <div class='img-wrap'>
    <label :for="imgType">
      <img class="img-preview" :src="imgUrl" alt="">
      <input 
        type="file" 
        class="file" 
        accept="image/*"
        :ref="'inputRef'" 
        :id="imgType" 
        @change="onUpload">
    </label>
    <span v-show="initImg" class='prev-tip'>
      {{ imgType== 'imgFront' ? '点击重新拍摄人像页' : '点击重新拍摄国徽页'}}
    </span>
    <div class="progress-wrap" v-show="uploading">
      <mt-progress :value="uploadPercent" :bar-height="8"></mt-progress>
    </div>
  
  </div>
</template>

<script>
  import {
    compress,
    checkImgUpload,
    userStatus
  } from '@utils/uploadImg'
  import {
    MessageBox,
    Progress
  } from 'mint-ui'
  import {
    uploadIdCard
  } from '@api/user'
  let timer;
  export default {
    components: {},
    props: ['imgType', 'initImg'],
    data() {
      return {
        uploading: false,
        uploadPercent: 0,
        imgUrl: this.imgType == 'imgFront' ? require('@assets/images/user/bg-a.png') : require('@assets/images/user/bg-b.png')
      };
    },
    beforeDestroy() {
      const messageBox = document.getElementsByClassName('mint-msgbox-btn mint-msgbox-cancel ');
      
      if (messageBox && messageBox[0]) {
        messageBox[0].click()
      }
      if (timer) {
        clearTimeout(timer);
      }
    },
    watch: {
      initImg: {
        immediate: true,
        handler() {
          if (this.initImg) {
            this.imgUrl = this.initImg
          }
        }
      }
    },
    methods: {
      onUpload(e) {
        this.uploading = true;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          this.uploading = false;
        }, 20000)
        this.uploadPercent = 0
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          this.uploading = false;
          return false;
        }
        let photo = files[0]
        if (!checkImgUpload(photo)) {
          this.uploading = false;
          return false;
        }
        // 预览
        this.imgPreview(photo);
      },
      //获取图片
      imgPreview(photo) {
        this.$refs.inputRef.value = '';
        let self = this;
        //判断支不支持FileReader
        if (!photo || !window.FileReader) {
          this.uploading = false;
          return;
        }
        if (!/^image/.test(photo.type)) {
          this.uploading = false;
          return;
        }
        //创建一个reader
        let reader = new FileReader();
        //将图片转成base64格式
        reader.readAsDataURL(photo);
        //读取成功后的回调
        reader.onloadend = function() {
          let result = this.result;
          let img = new Image();
          img.src = result
          img.onload = function() {
            let data = compress(img)
            self.imgUrl = data
            let sendData = {
              imgType: self.imgType,
              imgBase64: data
            }
            uploadIdCard(sendData, (res) => {
              let loaded = res.loaded,
                total = res.total;
              self.$nextTick(() => {
                self.uploadPercent = (loaded / total) * 100
              })
            }).then((res) => {
              self.uploading = false;
              let backInfo = {
                imgType: self.imgType,
                msg: res.message,
                status: false
              }
              if (res.code !== 200) {
                MessageBox(res.message)
                // 向上传递参数
                self.$emit('upBace', backInfo)
                return
              }
              backInfo.status = true
              if (res.data.userName) {
                backInfo.userName = res.data.userName
              }
              if (res.data.idNum) {
                backInfo.idNum = res.data.idNum
              }
              backInfo.userState = res.data.state
              let msg = userStatus(res.data.state)
                backInfo.msg = msg
              // if(res.data.state !== 2){
              //     MessageBox(msg)
              // }
  
              self.$emit('upBace', backInfo)
            }).catch(err => {
              self.uploading = false;
            })
          }
        }
      }
    }
  }
</script>

<style lang='less' scoped>
  .img-wrap {
    height: 100%;
    position: relative;
  }
  
  label {
    width: 100%;
    height: 100%;
  }
  
  input.file {
    display: none;
  }
  
  .img-preview {
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
  
  img {
    max-width: 100%;
  }
  
  .progress-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5)
  }
  
  .mt-progress {
    width: 80%;
    margin: 20% auto;
  }
  
  .prev-tip {
    position: relative;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    height: 2.5rem;
    line-height: 2.5rem;
    margin-top: -2.5rem;
    color: #fff;
    text-align: center;
  }
</style>